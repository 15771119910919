.invite-guests-dialog {
  .price-card {
    border: 1px solid #d7dbe8;
    border-radius: 7px;
    padding: 17px;
    cursor: pointer;
    .form-check-input:checked[type=radio] {
      background: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'><path fill='%23EE2768' d='M0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256zM371.8 211.8C382.7 200.9 382.7 183.1 371.8 172.2C360.9 161.3 343.1 161.3 332.2 172.2L224 280.4L179.8 236.2C168.9 225.3 151.1 225.3 140.2 236.2C129.3 247.1 129.3 264.9 140.2 275.8L204.2 339.8C215.1 350.7 232.9 350.7 243.8 339.8L371.8 211.8z'/></svg>") white;
      border-color: white;
    }
    input {
      margin-left: 10px;
      width: 25px;
      height: 25px;
      margin-top: 0;
      top: -1px;
      position: relative;
    }
    label {
      font-size: 15px;
      cursor: pointer;
    }
    span {
      font-size: 15px;
      font-weight: 500;
    }
  }
  .mat-dialog-title {
    font-family: var(--bs-body-font-family);
    font-size: var(--bs-body-font-size);
    font-weight: var(--bs-body-font-weight);
  }

  .completed {
    .line,
    .dot,
    .dot.selected {
      background: var(--bs-primary);
    }
  }

  .dot {
    height: 12px;
    width: 12px;
    background-color: #cccfd8;
    border-radius: 50%;
    display: inline-block;
    cursor: pointer;

    &.selected {
      background: #ee2768;
    }
  }

  .mat-dialog-content {
    // max-height: calc(100vh - 260px);
    margin: 0;
  }
  &.step-1-manual .mat-dialog-content {
    // max-height: calc(100vh - 420px);
  }

  .mat-dialog-actions {
    margin-top: 1.5rem;
  }
  &.step-1-manual .mat-dialog-actions {
    margin-top: 0;
  }

  .line {
    height: 1px;
    background: #cccfd8;

    &.last-line {
      background: #222222;
    }
  }

  .step-title {
    font-size: 50px;
  }

  .invite-type-container {
    max-width: 400px;
    width: 100%;
  }

  .select-info {
    color: #76787c;
  }

  .form-field {
    height: 47px;
  }

  .rounded-remove-button {
    width: 20px;
    height: 20px;
    font-size: 11px;
    background: #e1e4ee;
    color: #222222;
    padding: 0;
    border: 0;
  }

  .upload-container {
    height: 225px;
    border: 1px #babfcc dashed;
    padding: 30px;
    border-radius: 5px;

    &.dragging-files {
      background: #f0f0f0;
      border-color: var(--bs-primary);
    }
  }

  .file-name {
    font-size: 14px;
    unicode-bidi: isolate;
    direction: ltr;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}
