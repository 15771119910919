.guest-list {
  .guests-table {
    border-spacing: 0 2px;

    th {
      border-color: var(--bs-primary);
      border-top-width: 1px;
    }

    .column-date-desc {
      font-size: 0.75rem;
    }
  }
}
