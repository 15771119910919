.main-container.dialog-open {
  $blur-level: 8px;
  -webkit-filter: blur($blur-level);
  -moz-filter: blur($blur-level);
  -o-filter: blur($blur-level);
  -ms-filter: blur($blur-level);
  filter: blur($blur-level);
}

.rounded-dialog .mat-dialog-container {
  border-radius: 1.3rem;
  padding: 0;
}
.angled-dialog .mat-dialog-container {
  border-radius: 0;
  padding: 0;
}

.attending-scroll {
  &::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }
  &::-webkit-scrollbar-track {
    background: #e9ebf0;
    border-radius: 50px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #babfca;
    border-radius: 50px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #444444;
  }
}
